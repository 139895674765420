.CU-div {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }
  
  .CU-form {
    display: flex;
    flex-direction: column;
    
  }
  
  .textfield{
    width: 400px;
  }

  #submit-button {
      width: 150px;
      align-self: center;
  }

  .MuiInputBase-root {
      margin: .5em 0;
  }

  /* Media queries */
  @media all and (max-width: 600px) and (min-width: 300px) {
    .CU-div {
      width: 300px;
    }
    
    .textfield {
      width: 300px;
    }
  
    #submit-button {
      width: 125px;
    }

  }

@media all and (max-width: 1200px) and (min-width: 600px) {
  .CU-div {
    width: 300px;
  }
  
  .textfield {
    width: 300px;
  }

  #submit-button {
    width: 125px;
  }
}