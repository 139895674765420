@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

body {
	background-color: #f0f2f3;
	/* font-family: 'Berkshire Swash', cursive; */
}

body h1,h2,h3,span {
    font-family: 'Quicksand', sans-serif;
}

.home-paper {
	height: 600px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hero {
	width: 100%;
	max-width: 1800px;
	height: 600px;
	object-fit: cover;
	position: absolute;
	z-index: 0;
}

.hero-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.contact {
    width: 100%;
    height: 575px;
    margin: 4em 0 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    grid-column-start: 1;
    margin: 0 2em;
}

/* Media queries */
@media all and (max-width: 600px) and (min-width: 300px) {
	.home-paper, .hero {
        height: 300px;
    }
    
    .hero-img {
        height: 350px;
        width: auto;
    }

	.contactform {
		width: auto;
		margin: 0 0 2em 0;
	}

    .contactform h1 {
        font-size: 22px;
    }

}

@media all and (max-width: 1200px) and (min-width: 600px) {
	.home-paper, .hero {
        height: 300px;
    }
    
    .hero-img {
        height: 350px;
        width: auto;
    }
    
	.contactform {
		width: auto;
		margin: 0 0 2em 0;
	}

    .contactform h1 {
        font-size: 22px;
    }

}