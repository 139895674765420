.App {
  text-align: center;
  vertical-align: middle;
}

body {
  max-width: 1800px;
  margin: auto;
}

