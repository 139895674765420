@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200&display=swap');

.events {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
}

.events-title {
    margin-top: 15px;
    margin-bottom: 15px;
    grid-area: Title;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 3;
}

.announcements {
    display: flex;
    flex-direction: column;
}

.announcement1-text h3, .announcement2-text h3 {
    font-size: 24px;
}

.announcement1-div, .announcement2-div {
    display: flex;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.announcement1-text, .announcement2-text {
    width: 40%;
    line-height: 1.5;
    margin-left: 35px;
    margin-right: 35px;
}

.announcement1-text p, h3 {
    text-align: left;
    align-self: center;
    padding: 0 10px 0 15px;
}

.announcement2-text p {
    text-align: right;
    align-self: center;
    padding: 0 10px 0 15px;
}

.announcement1-text p, .announcement2-text p {
    font-size: 20px;
}

.announcement1 {
    display: flex;
    justify-self: center;
    margin-bottom: 15px;
}

.announcement2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.link {
    text-decoration: none;
    color: black;
    font-style: bold;
    font-size: 18px;
}

.announcement2-img {
    width: 40%;
    margin-right: 35px;
}

.announcement1-text, .announcement2-text, .social-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}


.announcement1-src, .social-src {
    height: 450px;
    box-shadow: -4px 8px 15px;
}

.announcement2-src {
    height: auto;
    max-width: 100%;
    box-shadow: -4px 8px 15px;
}

.divider {
    width: 100%;
}

.divider2 {
    margin-top: 25px;
}

/* Media queries */
@media all and (max-width: 600px) and (min-width: 300px) {
  
    .announcements {
		height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-between;
		padding: 1em 0;
		margin: 0 0;
	}

    .events-title {
        margin-bottom: 10px;
    }

    .events-title h1 {
        font-size: 22px;
    }

	.announcement1-div, .announcement2-div, .social-div {
		width: auto;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
	}

    .announcement1-text, .announcement2-text {
        width: 90%;
    }

    .announcement1-text h3, .announcement2-text h3 {
        font-size: 16px;
        margin: 0;
    }

	.announcement1-div p, .announcement2-div p, .social-div p {
		font-size: 14px;
        padding: 5px;
        margin: 5px;
	}

	.announcement1-src, .announcement2-src {
		width: 250px;
		height: auto;
	}

    .announcement2-img {
        margin-right: 0;
    }

    .list-header {
        font-size: 28px;
    }
}

@media all and (max-width: 1200px) and (min-width: 600px) {
	
    .announcements {
		height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: space-between;
		padding: 1em 0;
		margin: 0 0;
	}

    .events-title {
        margin-bottom: 10px;
    }

    .events-title h1 {
        font-size: 22px;
    }

	.announcement1-div, .announcement2-div, .social-div {
		width: auto;
        margin: 20px 0;
        display: flex;
        flex-direction: column;
	}

    .announcement1-text, .announcement2-text {
        width: 90%;
    }

    .announcement1-text h3, .announcement2-text h3 {
        font-size: 16px;
        margin: 0;
    }

	.announcement1-div p, .announcement2-div p, .social-div p {
		font-size: 14px;
        padding: 5px;
        margin: 5px;
	}

	.announcement1-src, .announcement2-src {
		width: 250px;
		height: auto;
	}

    .announcement2-img {
        margin-right: 0;
    }
}