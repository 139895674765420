@import url('https://fonts.googleapis.com/css2?family=Arima:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

.navbar {
	top: 0;
	height: 80px;
	width: 100%;
	max-width: 1800px;
	background-color: white;
	display: grid;
	grid-template-columns: 60% 40%;
	grid-template-rows: 1fr .5fr;
	position: fixed;
	z-index: 3;
}

.nav-date {
	grid-row-start: 1;
	grid-column-start: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	align-self: flex-start;
	margin-right: 3em;
	font-size: 14px;
}

.updated {
	color:rgb(82, 118, 173);
	font-style: italic;
}

.logo {
	grid-column-start: 1;
	grid-row-start: 2;
	width: auto;
	font-family: 'Quicksand', sans-serif;
	font-weight: 600;
	font-size: 35px;
	text-align: left;
	margin-left: 1em;
}

.logo a {
	text-decoration: none;
	align-self: center;
}

.logo a:hover, .links a:hover {
	color: #b89fc2;
	cursor: pointer;
}

.links {
	grid-row-start: 2;
	grid-column-start: 2;
	display: flex;
	justify-content: flex-end;
	align-items: end;
	font-family: 'Quicksand', sans-serif;
	font-size: 24px;
	font-weight: 600;
	margin-right: 30px;
}

.nav-events, .nav-reminders, .nav-contact {
	font-size: 18px;
	padding: 0 10px;
}

.links a {
	text-decoration: none;
	/* color: rgb(0, 146, 34); */
}



/* Media queries */
@media all and (max-width: 600px) and (min-width: 300px) {
	.navbar {
		height: 70px;
	}

	.logo {
		font-size: 24px;
		width: auto;
		margin-left: .5em;
	}

	.logo-text {
		font-size: 14px;
		margin: 0;
	}

	.links {
		height: 35px;
		flex-direction: column;
		margin: 0;
	}

	.nav-date {
		margin-right: 10px;
	}

	.nav-events,
	.nav-reminders,
	.nav-contact,
	.nav-date {
		font-size: 10px;
	}
}

@media all and (max-width: 900px) and (min-width: 600px) {
	.logo {
		font-size: 24px;
		width: auto;
		margin-left: .5em;
	}
	
	.logo-text {
		font-size: 24px;
		margin: 0 0.5em;
	}

	.links {
		margin-right: 10px;
		display: flex;
		height: 75px;
	}

	.nav-events,
	.nav-reminders,
	.nav-contact,
	.nav-date {
		font-size: 14px;
	}

	.nav-events,
	.nav-reminders,
	.nav-contact,
	.nav-date{
		margin: 0 0.5em;
	}
}